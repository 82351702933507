import { CommonMargin } from 'components/_layout/CommonMargin';
import SEO from 'components/_layout/Seo';
import React from 'react';
import { RelativeSection } from 'sections/Offer/Offer.styled';
import { TakeThisStepSection } from 'sections/_universal/TakeThisStepSection';
import { useBreakpoint } from 'utils/hooks/useBreakpoints';
import { OurOfferSection } from 'sections/_universal/OurOfferSection';
import styled from 'styled-components';
import { DistinctSection } from 'sections/Software/WebApps/components/DistinctSection';
import { YourDreamAppSection } from 'sections/Software/WebApps/components/YourDreamAppSection';
import { ChooseCodaheadSection } from 'sections/Software/WebApps/components/ChooseCodaheadSection';
import { BenefitsSection } from 'sections/Software/WebApps/components/BenefitsSection';
import { IndustriesSection } from 'sections/Software/WebApps/components/IndustriesSection';

export default () => {
  const isMd = useBreakpoint('md');

  return (
    <>
      <SEO
        title="Custom web application development services: building progressive web apps"
        meta={[
          {
            name: 'keywords',
            content:
              'custom web application development, custom web application development, custom web application development services, custom web application development companies, custom web application development solutions, Progressive Web Apps, building progressive web apps, progressive web apps agency, build progressive web apps, enterprise web development, enterprise web development company, web development company, enterprise web development services, enterprise web application development, enterprise web application development services, enterprise web app development, enterprise web app development services, custom web applications',
          },
        ]}
        description={
          'Professional custom web application development services at Codahead. We design and build reliable, user-friendly web apps, including cutting-edge progressive web applications.'
        }
      />

      <CommonMargin>
        <StyledSection title="Web Apps" variant="secondary" id="webApps" isMobile={isMd} withBreadCrumbs>
          <HeaderSection>
            A powerful web app in today's digital world is more than just an asset—it’s the heart of your online
            strategy. At Codahead, we craft custom web applications that combine sleek design, robust functionality, and
            cutting-edge technology. Whether you’re launching a startup, scaling a business, or enhancing customer
            engagement, our team delivers tailored web solutions that help you succeed.
          </HeaderSection>
        </StyledSection>

        <DistinctSection />
        <YourDreamAppSection />
        <ChooseCodaheadSection />
        <BenefitsSection />
        <IndustriesSection />
        <OurOfferSection />
        <TakeThisStepSection />
      </CommonMargin>
    </>
  );
};

const HeaderSection = styled.div`
  margin-top: 40px;
  max-width: 900px;
  font-size: 20px;
  line-height: 1.65;
  font-weight: 300;
`;

const StyledSection = styled(RelativeSection)`
  margin-top: 24px;
`;
